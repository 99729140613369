
.repricer-site {
    .myprofile {
        
        .myprofile-container {
            display: flex;
            justify-content: center;
        }
        
        .myprofile-form {
            margin-top: 20px;
            max-width: 720px;
            width: 80%;
            
            .update-img-container {
                margin-bottom: 16px;
                text-align: center;
            }
            
            .profile-img {
                margin-bottom: 16px;
                text-align: center;
                
                img {
                    height: 240px;
                    width: auto;
                }
            }
            
            .myprofile-button {
                text-align: center;
            }
        }
    }
    .geosuggest__suggests--hidden {
        max-height: 0;
        overflow: hidden;
        border-width: 0;
      }
}
  