@import './repricer.scss';

$max-width: 1300px;
$item-width: 264px;
$item-padding: 24px;//($max-width - 8px  - 4 * $item-width)/3;

.repricer-site {
    .flex-row-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        
        &.backwards-row {
            flex-direction: row-reverse;
        }
        
        &.centered-row {
            justify-content: center;
            align-items: center;
        }
        
        @media (max-width: 767px) {
            flex-direction: column;
            
            &.backwards-col {
                flex-direction: column-reverse;
            }
            &.centered-col {
                justify-content: center;
                align-items: center;
            }
        }
        
        &.no-break {
            flex-direction: row !important;
            
            .flex-row-fixed {
                width: auto !important;
            }
            .flex-row-fill {
                width: auto !important;
            }
        }
        
        &.md-break {
            @media (max-width: 991px) {
                flex-direction: column;
                
                &.backwards-col {
                    flex-direction: column-reverse;
                }
                &.centered-col {
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        
        &.lg-break {
            @media (max-width: 1199px) {
                flex-direction: column;
                
                &.backwards-col {
                    flex-direction: column-reverse;
                }
                &.centered-col {
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        
        .flex-row-fixed {            
            @media (max-width: 767px) {
                width: 100%;
            }
        }
        
        .flex-row-fill {   
            flex-grow:1;
            @media (max-width: 767px) {
                width: 100%;
            }
            
            &.right {
                width: 100%;
                justify-content: right;
                display: flex;
            }
        }
    }
    .flex-row-container-nocol {
        width: 100%;
        display: flex;
        flex-direction: row;
        
        &.backwards-row {
            flex-direction: row-reverse;
        }
        &.centered-row {
            justify-content: center;
            align-items: center;
        }
        
        
        .flex-row-fixed {        
        }
        
        .flex-row-fill {   
            flex-grow:1;
            
            &.right {
                width: 100%;
                justify-content: right;
                display: flex;
            }
        }
    }
}
  