


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.349);
}

.dropdown-toggle::after {
    display: none !important; 
}