
$header-break: 1000px;

.repricer-site {
    
    .search-header {
        display: flex;
        flex-direction: column;
        
        align-items: center;
        justify-content: left;
    }
    
}
  