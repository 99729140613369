
.shimmer{
	background-color: #e2e5e7;
	// The shine that's going to move across the skeleton:
	background-image:			
			linear-gradient(
				90deg, 
				rgba(#fff, 0), 
				rgba(#fff, 0.5),
				rgba(#fff, 0)
			);
	background-size: 400px 100%; // width of the shine
	background-repeat: no-repeat; // No need to repeat the shine effect
	background-position: left -400px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
	animation: shine 1s ease infinite; // in
}
@keyframes shine {
	to {
		// Move shine from left to right, with offset on the right based on the width of the shine - see background-size
		background-position: right -400px top 0;
	}
}
