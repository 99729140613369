
.repricer-site {
    h1,h2,h3 {
        color: black;
    }
    
    .info-dlg {
        width: 100%;
        height: 100%;
        
        .info-dlg-container {
            display: flex;
            justify-content: center;
        }
        
        .info-dlg-body {
            width: 400px;
            
            background-color: white;
            margin-top: 50px;
            margin-bottom: 30px;
            border-radius: 10px;
            
            padding: 35px;
            text-align: center;
            
            border: 1px solid rgba(120,120,120,0.5);
        }
        
        .button-action {
            width: 100%;
        }
        
        .linkedin {
            background-color: #0777ac;
            border: 1px solid #0777ac;
            
            &:active {
                color: white;
                box-shadow: 0 0 0 .25rem rgba(7, 119, 172, .4);
            }
            &:hover {
                color: white;
                box-shadow: 0 0 0 .25rem rgba(7, 119, 172, .4);
            }
        }
    }
    
}
  