.repricer-site {
    .footer {
        margin-top: auto;
        padding-top: 24px;
        padding-bottom: 10px;

        color: white;
       
        .menu-responsive {
            display: flex;
            color: #fff;
            align-items: flex-start;
            align-content: flex-start;
            flex-direction: row;
            
            .left {
                max-width: 450px;
                padding-bottom: 30px;
                
                color: #fff;
                @media (max-width: 767px) {
                    max-width: 767px;
                }
            }
            .fill-remaining-space {
                flex: auto;
                min-width:30px;
                @media (max-width: 767px) {
                    display: none;
                }
            }
            .right {
                color: #fff;
                min-width: 190px;
                max-width: 190px;
                @media (max-width: 767px) {
                    max-width: 767px;
                }
            }
            
            @media (max-width: 767px) {
                flex-direction: column;
            }
        }
       
        
        .logo {
            margin-bottom: 14px;
            max-width: 253px;
            min-height: 36px;
            max-height: 96px;
            width: auto;

            background: var(--logo-img);
            background-repeat: no-repeat;
        }
        
        .social-container {
            padding-top: 10px;
            .social {
                width: 32px;
                height: 32px;
                color: white !important;
    
                border: 1.5px solid white !important;
                border-radius: 30px;
                
                display: flex;
                justify-content: center;
                align-items: center;
                float: left;
                
                margin-right: 6px;
            }
        }
        
        .subhead {
            color: white;
        }
        
        .footer-menu {
            .footer-link-header, .social-href {
                color: white;
                font-weight: 300;
            }
            .footer-link-href {
                color: white;
                font-weight: 300;
            }
            
        }
        
        .footer-links {
            padding: 0px;
            list-style-type: none;
            font-weight: 300;
            font-size: 16px;
            line-height: 27px;
            color: white !important;
        }
    }
}