
    .loading-overlay {
        background-color: rgba(0,0,0,0.8);
        z-index: 999;
        position: fixed;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;   
        
        
        display: flex;
        justify-content: center;
        align-items: center;
        
        
        .spinner {
            width: 100px;
            height: 100px;
            color: white;//var(--repricer-primary-other);
            
            animation-name: spin;
            animation-duration: 1000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear; 
        }
        
        @keyframes spin {
            from {
                transform:rotate(0deg);
            }
            to {
                transform:rotate(360deg);
            }
        }
    }