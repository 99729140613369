
.repricer-site {
    h1,h2,h3 {
        color: black;
    }
    
    .signin-cover {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        z-index: -1;
    }
    .signin {
        
        .signin-container {
            display: flex;
            justify-content: center;
        }
        
        .signin-form {
            width: 496px;
            
            background-color: white;
            margin-top: 50px;
            margin-bottom: 30px;
            border-radius: 10px;
            
            padding: 35px;
            
            border: 1px solid rgba(120,120,120,0.5);
        }
        
        .recaptcha {
            width: 275px;
            xheight: 80px;
        }
        
        .button-action {
            width: 100%;
        }
        
        .linkedin {
            background-color: #0777ac;
            border: 1px solid #0777ac;
            
            &:active {
                color: white;
                box-shadow: 0 0 0 .25rem rgba(7, 119, 172, .4);
            }
            &:hover {
                color: white;
                box-shadow: 0 0 0 .25rem rgba(7, 119, 172, .4);
            }
        }
    }
    
}
  