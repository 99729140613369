
.repricer-site {
    .contactus {
        
        .contactus-container {
            display: flex;
            justify-content: center;
        }
        
        .contactus-form {
            margin-top: 20px;
            max-width: 720px;
            width: 400px;
            
            @media (min-width: 651px) {
                width: 720px;
            }
            
            @media (max-width: 650px) {
                width: 600px;
            }
            
            @media (max-width: 450px) {
                width: 100%;
            }
            
            .contactus-button {
                min-width: 150px;
            }
        }
    }
    
}
  