$banner-height: auto;

.repricer-site {
    .hero-content {
        color: white;
        text-align: left;
        height: $banner-height;
        xposition: relative;
        @media (max-width: 767px) {
            display:none;
        }
        
        .input-group {
            border-radius: 100px;
            
            .input-group-text {
                border-radius: 100px 0px 0px 100px;
            }
            
            .form-control {
                border-radius: 0px 100px 100px 0px;
            }
        }
        
        .hero-content-padding {
            padding: 10px 10px 10px 10px;
            height: $banner-height;
            
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            
        }
        
        .hero-center {
            display: flex;
            align-items: center;
        }
        
        h1,h2,h3,h4 {
            color: white;
        }
        
        .center {
            text-align: center;
        }
        .hero-logo {
            height: auto;
            width: auto;
            max-height: 96px;
            max-width: 341px;
        }
        .searchPrefix {
            background-color: white;
            color: #262626;
            border: 0px;
        }
        .searchform {
            max-width: 500px;
            width:100%;
            display: inline-block;
            
            .repricer-border {
                border: 1px solid var(--repricer-primary);
                border-radius: 100px;
            }
            
            .input-no-outline {
                outline: none;
                box-shadow: none;
                border: 0px;
                color: #262626;
            }
        }
    }
    
}
  