$gradColor: #0d2144;
$gradientA: scale-color($gradColor, $lightness: -10%);
$gradientB: scale-color($gradColor, $lightness: -30%);

html, body {
    width: 100%;
    height: 100%;
}

body {
    font-size: 1.1rem;
    color: #262626;  
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
}
.filled {
    width: 100%;
    height: 100%;
}
.debug {
    background-color: yellow;
    xborder: 1px solid var(--repricer-primary);
    
}
.repricer-site {
    font-family: "Lato Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--repricer-text-color);
    
    // for making footer stay at bottom
    display: flex;
    flex-direction: column;
    height: 100%;

    
    .site-body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        
        width:100%;
        height: auto;
    }
    .body-bold {
        font-family: "Lato Regular";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }
    
    .body-content {
        padding: 10px;
    }
    
    h1, .h1 {
        font-family: "Lato Regular";
        font-style: normal;
        font-weight: normal;
        font-size: 50px;
        line-height: 58px;
        color: black;//var(--repricer-primary); 
    }
    
    h2, .h2 {
        font-family: "Lato Regular";
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        line-height: 46px;
        color: black;//var(--repricer-primary-other);
    }
    
    h3, .h3 {
        font-family: "Lato Regular";
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 32px;
        color: black;//var(--repricer-primary-other);
    }
    
    h4, .h4 {
        font-family: "Lato Regular";
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 28px;
        color: black;//var(--repricer-primary-other);
    }
    
    
    .quote {
        font-family: "Lato Regular";
        font-style: italic;
        font-weight: 300px;
        font-size: 16px;
        line-height: 20px;
    }
    
    .float-left {
        float: left;
    }
    
    .float-right {
        float: right;
    }
    
    .center {
        text-align: center;
    }
    
    .dark-green-text {
        color: var(--repricer-dark-green);
    }
    
    a {
        color: var(--repricer-dark-green);
        font-weight: 400;
    }
    
    a:hover {
        color: var(--repricer-dark-green);
        cursor: pointer;
    }
    
    .caption {
        font-family: "Lato Regular";
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
    }
    
    .caption-bold {
        font-family: "Lato Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }
    
    .navurl {
        cursor: pointer;
    }
    
    card, .card {
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
    }
    
    .separator {
        border-top: 1px solid #818181;
        margin: auto;
    }
    
    .button-action {
        font-size: 1.1rem;
        color: #ffffff;
        background-color: var(--repricer-dark-green);
        border-radius: 0.4rem;
        padding: 0.4rem 0.8rem;
        box-shadow: 0px 0px 0px;
        border: 1px solid var(--repricer-dark-green);
        margin: .25rem;
        
        &.bg-repricer-dark {
            color: var(--repricer-primary-other);
        }
        
        &:active {
            color: white;
            box-shadow: 0 0 0 .25rem rgba(var(--repricer-dark-green-rgb), .4);
        }
        &:hover {
            color: white;
            box-shadow: 0 0 0 .25rem rgba(var(--repricer-dark-green-rgb), .4);
        }
        
        &.activated {
            color: var(--repricer-dark-green);
            background-color: #ffffff;
            border: 1px solid var(--repricer-dark-green);
            
            &.bg-repricer-dark {
                background-color: var(--repricer-primary-other);
            }
            
        }
        
        &.inactive {
            cursor: default !important;
            color: #ffffff;
            background-color: var(--repricer-disable-button);
            border: 1px solid var(--repricer-disable-button);
            &:active {
                box-shadow: 0 0 0 0 rgba(var(--repricer-disable-button-rgb), 0) !important;
            }
            &.activated {
                box-shadow: 0 0 0 0 rgba(var(--repricer-disable-button-rgb), 0) !important;
            }
            &:hover {
                color: white;
                box-shadow: 0 0 0 0 rgba(var(--repricer-disable-button-rgb), .4) !important;
            }
        }
    }
    
    .button-action-bright {
        font-size: 1.1rem;
        color: #ffffff;
        background-color: var(--repricer-primary);
        border-radius: 0.4rem;
        padding: 0.4rem 0.8rem;
        box-shadow: 0px 0px 0px;
        border: 1px solid var(--repricer-primary);
        margin: .25rem;
        
        &.bg-repricer-dark {
            color: var(--repricer-primary-other);
        }
        
        &:active {
            color: white;
            box-shadow: 0 0 0 .25rem rgba(var(--repricer-primary-rgb), .4);
            &.bg-repricer-dark {
                color: var(--repricer-primary-other);
            }
        }
        &:hover {
            color: white;
            box-shadow: 0 0 0 .25rem rgba(var(--repricer-primary-rgb), .4);
            &.bg-repricer-dark {
                color: var(--repricer-primary-other);
            }
        }
        
        &.activated {
            color: var(--repricer-primary);
            background-color: #ffffff;
            border: 1px solid var(--repricer-primary);
            
            &.bg-repricer-dark {
                background-color: var(--repricer-primary-other);
            }
            
            &:hover {
                color: var(--repricer-primary);
                box-shadow: 0 0 0 .25rem rgba(var(--repricer-primary-rgb), .4);
                &.bg-repricer-dark {
                    background-color: var(--repricer-primary-other);
                    color: var(--repricer-primary);
                }
            }
        }
        
        &.inactive {
            color: #ffffff;
            background-color: var(--repricer-disable-button);
            border: 1px solid var(--repricer-disable-button);
            &:active {
                box-shadow: 0 0 0 .25rem rgba(var(--repricer-disable-button-rgb), 0);
            }
        }
    }
    
    .xprimary-bg {
        background: var(--repricer-dark-green);
        mix-blend-mode: color;
    }
    .xreset-mix-mode {
        mix-blend-mode: normal;
    }
    
    .secondary-bg {
        width: 100%; 
        height: 100%; 
        background: var(--repricer-primary-other);
        mix-blend-mode: color;
    }
    .xbg-test {
        width: 100%; height: 100%; background-color: red;
    }
    .repricer-gradient-primary {
        background-color: var(--repricer-primary);
    }
    .repricer-gradient-secondary {
        background-color: var(--repricer-primary-other);
    }
    .repricer-gradient-primary-globe {
        background-blend-mode: lighten;
        background-size: cover;
        background-repeat:no-repeat;
        mix-blend-mode: screen;
    }
    .repricer-gradient-secondary-globe {
        background-blend-mode: lighten;
        background-size: cover;
        background-repeat:no-repeat;
        mix-blend-mode: screen;
    }
    
    .fill {
        width: 100%;
        height: 100%;
    }
}
  