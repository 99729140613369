
.repricer-site {
    .banner-content {
        padding: 0px;
        height: auto;
        max-height: 256px;
        display: flex;
        justify-content: center;
        
        .banner-img {
            
            max-width: 1440px;
            width: 100%;
            height:auto;
            overflow:hidden;
            object-fit: cover;
        }
    }
    
}
  